import { Component, Input } from '@angular/core';
import { GithubService } from 'src/app/github.service';

@Component({
  selector: 'app-github-logout-button',
  templateUrl: './github-logout-button.component.html',
  styleUrls: ['./github-logout-button.component.css']
})
export class GithubLogoutButtonComponent {
  @Input() isAuthenticated: boolean = false;

  constructor(private githubService: GithubService) { }

  logout() {
    this.githubService.logout();
  }
}
