import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { ServerService } from 'src/app/server.service';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// Interface to define the structure of data passed to the dialog
export interface DialogData {
  hash: string; // Contains a hash value, purpose dependent on the implementation
}

@Component({
  selector: 'app-dashboard-auth',
  templateUrl: './dashboard-auth.component.html',
  styleUrls: ['./dashboard-auth.component.css'],
})
export class DashboardAuthComponent {
  @Output() verificationData = new EventEmitter<any>(); // Output event to send verification data

  email: string = ''; // User's email input
  token: string = ''; // Token input for verification
  emailVerified: boolean = false; // Flag to track if email is verified
  tokenError: boolean = false; // Flag to indicate token verification errors
  emailError: boolean = false; // Flag to indicate email input errors
  isEmailValid: boolean = false; // Flag to indicate if the email format is valid
  isTokenValid: boolean = false; // Flag to indicate if the token format is valid
  isLoading: boolean = false; // Flag to indicate loading state during async operations

  constructor(
    private serverService: ServerService, // Service for server-related operations
    public dialogRef: MatDialogRef<DashboardAuthComponent>, // Reference to the dialog
    @Inject(MAT_DIALOG_DATA) public data: DialogData // Injected data for the dialog
  ) {}

  // Validates the email format using regex
  validateEmail(): void {
    const emailRegex = new RegExp('^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$');
    this.isEmailValid = emailRegex.test(this.email);
  }

  // Validates the token format (8-digit number)
  validateToken(): void {
    const tokenRegex = /^[0-9]{8}$/; // Regex for 8-digit number
    this.isTokenValid = tokenRegex.test(this.token);
  }

  // Sends the email for dashboard token verification
  sendEmail(): void {
    this.isLoading = true; // Set loading state
    const jsonStr = JSON.stringify({ email: this.email }); // Prepare email as JSON
    const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString(); // Encrypt email

    // Send the encrypted email to the server
    this.serverService.SendDashboardTokenMail({ data: encryptedObject }).then(
      response => {
        // Handle server response
        if (response.out !== 'error') {
          this.emailVerified = true; // Show token input form upon success
        } else {
          this.emailError = true; // Show error message
        }
        this.isLoading = false; // Reset loading state
      },
      error => {
        console.error('Error sending email:', error); // Log the error
        this.emailError = true; // Show error message
        this.isLoading = false; // Reset loading state
      }
    );
  }

  // Verifies the token entered by the user
  verifyToken(): void {
    this.isLoading = true; // Set loading state
    const jsonStr = JSON.stringify({ email: this.email, token: this.token }); // Prepare data
    const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString(); // Encrypt data

    // Send the encrypted data for verification
    this.serverService.VerifyDashboardToken({ data: encryptedObject }).then(
      (response: any) => {
        if (response && typeof response === 'object' && 'isValid' in response) {
          if (response.isValid) {
            this.tokenError = false; // Reset token error
            this.verificationData.emit(response.data); // Emit verified data to parent component
          } else {
            this.tokenError = true; // Show token error
          }
        } else {
          console.error('Unexpected response format'); // Log unexpected format
          this.tokenError = true; // Show token error
        }
        this.isLoading = false; // Reset loading state
      },
      error => {
        console.error('Verification error:', error); // Log the error
        this.tokenError = true; // Show token error
        this.isLoading = false; // Reset loading state
      }
    );
  }
}
