import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormComponent } from './components/form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ServerService } from './server.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GithubComponent } from './components/github/github.component';
import { GithubCallbackComponent } from './components/github-callback/github-callback.component';
import { GithubIconComponent } from './components/github-icon/github-icon.component';
import { GithubLoginButtonComponent } from './components/github-login-button/github-login-button.component';
import { GithubLogoutButtonComponent } from './components/github-logout-button/github-logout-button.component';
import { environment } from './environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { ResultComponent } from './components/result/result.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReportComponent } from './components/report/report.component';
import { ReportAuthComponent } from './components/report-auth/report-auth.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardAuthComponent } from './components/dashboard-auth/dashboard-auth.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    GithubComponent,
    GithubCallbackComponent,
    GithubIconComponent,
    GithubLoginButtonComponent,
    GithubLogoutButtonComponent,
    ResultComponent,
    PageNotFoundComponent,
    ReportComponent,
    ReportAuthComponent,
    ProgressBarComponent,
    DashboardComponent,
    DashboardAuthComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RecaptchaV3Module,
    MatDialogModule,
    MatTableModule,
    MatListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSortModule
  ],
  providers: [ServerService, DeviceDetectorService, { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey }],
  bootstrap: [AppComponent]
})
export class AppModule { }