<!DOCTYPE html>
<html>
<header>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</header>

<body>
    <div class="main-container" style="text-align: center;">
        <div class="top-container">
            <img class="SetInStone-logo" src="../../../assets/logosetinstone.webp" alt="Set In Stone Logo">
            <h1 class="home-title"><a href="{{ LAB_URL }}">Home</a> | <a href="{{ URL }}">Pre-Audit</a></h1>
        </div>
        <h1 style="color: white; font-size: 1.85rem; font-weight: 700; margin-bottom: 1.75rem;">Fundamental Security Confirmation</h1>
        <div class="container">
            <div *ngIf="isLoading" style="margin-top: 1rem;">
                <div class="loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
            </div>
            <app-progress-bar *ngIf="displayData.stoneScore" [score]="displayData.stoneScore" [message]="displayData.message2"></app-progress-bar>

            <h2 class="message" *ngIf="displayData.message2">{{ displayData.message2 ? displayData.message2 : " " }}</h2>
            <h2 class="message" *ngIf="displayData.message" style="margin-top: 2.25rem;"><strong>Expert opinion: </strong>{{ displayData.message ? displayData.message : " " }}</h2>
            <div class="bottom-container2" *ngIf="displayData.auditBookingURL" style="margin-top: 2.25rem; margin-bottom: 2.25rem;">
                <h1><a href="{{ URL }}">{{linkButton}}</a></h1>
            </div>
            <h2 class="upper-title" *ngIf="displayData.creationTime" style="margin-bottom: .65rem;">Generated on</h2>
            <h2 class="time-content" *ngIf="displayData.creationTime">{{ displayData.creationTime ?
                displayData.creationTime : " "}}</h2>
            <h2 class="upper-title" *ngIf="displayData.expirationTime" style="margin-top: 2.25rem; margin-bottom: .65rem;">Valid until</h2>
            <h2 class="time-content" *ngIf="displayData.expirationTime">{{ displayData.expirationTime ?
                displayData.expirationTime : " "}}</h2>
            <h2 class="upper-title" *ngIf="displayData.fileName && isUpload" style="margin-top: 2.25rem; margin-bottom: .65rem;">ARCHIVE NAME</h2>
            <h2 class="lower-title" *ngIf="displayData.fileName && isUpload">{{ displayData.fileName ?
                displayData.fileName : " " }}</h2>
            <h2 class="upper-title" *ngIf="displayData.repositoryType && !isUpload" style="margin-top: 2.25rem; margin-bottom: .65rem;">{{ displayData.repositoryType ?
                displayData.repositoryType : " " }} GITHUB URL</h2>
            <h2 class="lower-title" *ngIf="displayData.GitHubURL && !isUpload"><a href="{{ displayData.GitHubURL }}"
                    target="_blank">{{
                    displayData.GitHubURL ? displayData.GitHubURL : " " }}</a></h2>
            <h2 class="upper-title" *ngIf="displayData.notionURL" style="margin-top: 2.25rem; margin-bottom: .65rem;">VULNERABILITIES REPORT</h2>
            <h2 class="lower-title" *ngIf="displayData.notionURL" style="margin-bottom: 2.5rem;"><a href="{{ displayData.notionURL }}">{{
                    displayData.notionURL ? displayData.notionURL : " " }}</a></h2>
            <div class="links">
                <a (click)="copyToClipboard()"><img class="copy-link-icon" src="../../../assets/CopyLinkWhiteIcon.png"
                        alt="Copy Link Icon"></a>
                <a href="{{ shareOnTwitter }}"><img class="X-icon" src="../../../assets/XWhiteIcon.jpg"
                        alt="Share on X"></a>
                <a href="{{ shareOnTelegram }}"><img class="telegram-icon" src="../../../assets/TelegramWhiteIcon.jpg"
                        alt="Share on Telegram"></a>
                
            </div>
        </div>

        <div class="bottom-container" *ngIf="displayData.auditBookingURL">
            <h1 class="bottom-container-title">Pre-audit is good, full audit is required</h1>
            <h1><a href="{{ displayData.auditBookingURL }}">Book a full audit session right now</a></h1>
        </div>
    </div>
</body>

</html>
