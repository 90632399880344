// github.component.ts
import { MatSelectChange } from '@angular/material/select'
import { Component, Input, OnInit } from '@angular/core'
import { GithubService } from '../../github.service'
import { ServerService } from 'src/app/server.service'
import { environment } from 'src/app/environments/environment'

@Component({
  selector: 'app-github',
  templateUrl: './github.component.html',
  styleUrls: ['./github.component.css'],
})

/* eslint-disable @typescript-eslint/no-explicit-any */
export class GithubComponent implements OnInit {
  isAuthenticated: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() formData: any;
  repos: any[] = [];
  branches: any[] = [];
  commits: any[] = [];
  reposLoaded: boolean = false;
  branchesLoaded: boolean = false;
  commitsLoaded: boolean = false;
  isSelectedFile: boolean = false;
  selectedRepo: string = '';
  selectedBranch: string = '';
  selectedCommit: string = '';
  username: string = '';
  obj: any= {}

  constructor(
    private githubService: GithubService,
    public server: ServerService,
  ) { }

  ngOnInit() {
    this.server.logoutGithub.subscribe(() => {
      this.isAuthenticated = false;
      this.repos = [];
      this.branches = [];
      this.commits = [];
      this.reposLoaded = false;
      this.branchesLoaded = false;
      this.commitsLoaded = false;
    })

    this.server.updateIsSelectedFile.subscribe(() => {
      this.isSelectedFile = this.server.isSelectedFile;
    })

    this.githubService.isAuthenticated$.subscribe((isAuth: boolean) => {
      if (isAuth) {
        this.isAuthenticated = true
        this.server.isAuth = true
      }
    })

    // If authenticated, fetch user repos
    if (this.isAuthenticated) {
      this.githubService.getRepos().subscribe((repos: any) => {
        this.repos = repos
        this.reposLoaded = true
      })

      //Retrieve user details if logged in
      this.githubService.getUserDetails().subscribe(
        (userDetails) => {
          this.username = userDetails.data.login
          //Retrieve app installed on user account
          this.githubService.getUserInstall().subscribe(async (res: any) => {
            if (res.status == 200) {
              //If no app installed on user account, redirect to our github app installation page
              if (res.data.installations.length == 0) {
                window.location.href = `https://github.com/apps/${environment.appName}/installations/new`
              } else {
                let installed = false;
                //If app already installed, check for our App with the appID.
                if(res.data.installations){
                  for(let e of res.data.installations) {
                    if(e.app_id == environment.appId) {
                      installed = true;
                    }
                  }
                }
                //If user don't have our appID in his installations, redirect to github app install page
                if(!installed) {
                  window.location.href = `https://github.com/apps/${environment.appName}/installations/new`
                }
              }
            }
          })
        },
        (error) => {
          console.error('Error fetching user details:', error)
        },
      )
    }
  }

  onRepoSelectionChange(newValue: MatSelectChange) {
    if (newValue.value != undefined) {
      const selectedRepo = this.repos.find((repo) => repo.name === newValue.value)
      const sizeInMb = selectedRepo.size / 1024
      if (selectedRepo) {
        // Check if the user is already authenticated
        const encryptedAccessToken = sessionStorage.getItem('token')
        if (encryptedAccessToken) {
          this.githubService.getBranches(this.selectedRepo, this.username).subscribe((branches: any) => {
            this.branches = branches;
            this.branchesLoaded = true;
            console.log('this.branches', this.branches)
          });
          this.obj = {
            repo: newValue.value,
            size: sizeInMb,
            username: selectedRepo.owner.login,
            key: encryptedAccessToken,
            url: selectedRepo.html_url,
          }
          this.server.repoData = this.obj
          this.server.updateRepoData.emit()
        }
      }
    } else {
      this.branches = [];
      this.commits = [];
      this.branchesLoaded = false;
      this.commitsLoaded = false;
      this.server.isRepoDataSet = false;
      this.server.repoData = '';
    }
  }

  onBranchSelectionChange(newValue: MatSelectChange): void {
    if (newValue.value != undefined) {
      this.selectedBranch = newValue.value;
      this.githubService.getCommits(this.selectedRepo, this.selectedBranch, this.username).subscribe((commits: any) => {
        console.log('commits', commits)
        this.commits = commits;
        this.commitsLoaded = true;
      });
      this.formData.branch = this.selectedBranch;  // Store the selected branch in formData
      this.obj.branch = this.selectedBranch;
      this.server.repoData = this.obj
      this.server.updateRepoData.emit()
    } else {
      this.commits = [];
      this.commitsLoaded = false;
    }
  }

  onCommitSelectionChange(newValue: MatSelectChange): void {
    if (newValue.value != undefined) {
      this.selectedCommit = newValue.value;
      this.formData.commit = this.selectedCommit;  // Store the selected commit in formData
      this.obj.commit = this.selectedCommit;
      this.server.repoData = this.obj
      this.server.updateRepoData.emit()
    }
  }
}
