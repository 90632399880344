<div class="report-auth-container">
    <div class="auth-header">
      <h2>Verify to access Dashboard</h2>
    </div>
  
    <form (ngSubmit)="emailVerified ? verifyToken() : sendEmail()">
      <div *ngIf="!emailVerified" class="auth-form">
        <div class="input-group">
          <label for="emailInput" class="auth-label">Email Address:</label>
          <input
            type="email"
            [(ngModel)]="email"
            (ngModelChange)="validateEmail()"
            [ngModelOptions]="{ standalone: true }"
            class="auth-input"
            id="emailInput"
            placeholder="Enter your email"
            required
          />
        </div>
        <button type="submit" [disabled]="!isEmailValid" class="auth-button">Send Verification Code</button>
        <div *ngIf="emailError" class="error-message">Invalid email. Try again.</div>
        <div *ngIf="isLoading" class="loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
  
      <div *ngIf="emailVerified" class="auth-form">
        <div class="input-group">
          <label for="tokenInput" class="auth-label">Verification Code:</label>
          <input
            type="text"
            [(ngModel)]="token"
            (ngModelChange)="validateToken()"
            [ngModelOptions]="{ standalone: true }"
            class="auth-input"
            id="tokenInput"
            placeholder="Enter verification code"
            required
          />
        </div>
        <button type="submit" [disabled]="!isTokenValid" class="auth-button">Verify Code</button>
        <div *ngIf="tokenError" class="error-message">Invalid code. Try again.</div>
        <div *ngIf="isLoading" class="loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </form>
  </div>
  