import { Component, Input } from '@angular/core'
import { ServerService } from 'src/app/server.service'
import { trigger, transition, style } from '@angular/animations'

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
  animations: [trigger('progressWidth', [transition('* => *', [style({ width: '0%' })])])],
})
export class ProgressBarComponent {
  @Input() score: any // Receive score from parent component
  @Input() message: any
  presetPercentages: number[] = [20, 40, 60, 80, 100]
  maxScore: number = 5000
  percent: any
  cssPercent: any
  cssPx: any
  progressWidthState: any
  isMobile: boolean = false
  grade: any
  color: any

  constructor(public server: ServerService) {
    // this.isMobile = this.server.isMobile;
  }

  ngOnChanges(): void {
    this.isMobile = this.server.isMobile

    if (!this.isMobile) {
      if (this.score >= this.maxScore) {
        this.percent = this.presetPercentages[0]
        this.cssPx = 85
        this.cssPercent = 25
        this.grade = 1
        this.color = '#F04038'
      } else if (this.score < this.maxScore && this.score >= 1000) {
        this.percent = this.presetPercentages[1]
        this.cssPx = 15
        this.cssPercent = 40
        this.grade = 2
        this.color = '#ff6700'
      } else if (this.score < 1000 && this.score >= 300) {
        this.percent = this.presetPercentages[2]
        this.cssPx = -85
        this.cssPercent = 60
        this.grade = 3
        this.color = '#F4B032'
      } else if (this.score < 300 && this.score >= 10) {
        this.percent = this.presetPercentages[3]
        this.cssPx = -225
        this.cssPercent = 80
        this.grade = 4
        this.color = '#469E68'
      } else if (this.score >= 0 && this.score < 10) {
        this.percent = this.presetPercentages[4]
        this.cssPx = -375
        this.cssPercent = 100
        this.grade = 5
        this.color = '#2E8B57'
      }
      this.progressWidthState = this.percent
    } else {
      if (this.score >= this.maxScore) {
        this.percent = this.presetPercentages[0]
        this.cssPx = 35
        this.cssPercent = 25
        this.grade = 1
        this.color = '#F04038'
      } else if (this.score < this.maxScore && this.score >= 1000) {
        this.percent = this.presetPercentages[1]
        this.cssPx = -10
        this.cssPercent = 40
        this.grade = 2
        this.color = '#ff6700'
      } else if (this.score < 1000 && this.score >= 300) {
        this.percent = this.presetPercentages[2]
        this.cssPx = -95
        this.cssPercent = 60
        this.grade = 3
        this.color = '#F4B032'
      } else if (this.score < 300 && this.score >= 10) {
        this.percent = this.presetPercentages[3]
        this.cssPx = -155
        this.cssPercent = 80
        this.grade = 4
        this.color = '#469E68'
      } else if (this.score >= 0 && this.score < 10) {
        this.percent = this.presetPercentages[4]
        this.cssPx = -190
        this.cssPercent = 100
        this.grade = 5
        this.color = '#2E8B57'
      }
      this.progressWidthState = this.percent
    }
  }
}
