import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class ServerService {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public logInGithub = new EventEmitter<any>();
  public logoutGithub = new EventEmitter<any>();
  public updateRepoData = new EventEmitter<any>();
  public updateIsSelectedFile = new EventEmitter<any>();

  public requestUrl = environment.requestUrl;
  public headers: any;
  public isMobile: boolean = false;
  public isAuth: boolean = false;
  public repoData: any;
  public isRepoDataSet: boolean = false;
  public isSelectedFile: boolean = true;

  constructor(private http: HttpClient, private deviceService: DeviceDetectorService, private router: Router) {
    this.isMobile = this.deviceService.isMobile();

    this.logInGithub.subscribe(() => {
      this.isAuth = true;
      window.location.href = environment.homeUrl;
    });

    this.logoutGithub.subscribe(() => {
      this.isAuth = false;
      window.location.href = environment.homeUrl;
    });

    this.updateRepoData.subscribe(() => {
      this.isRepoDataSet = true;
    });
  }

  private async request(method: string, url: string, type: any, data?: any, headers?: any) {
    const timestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
    const sharedSecret = environment.secret;
    data.timestamp = timestamp;

    // Sign the request with the shared secret
    const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
        
    // Set signature in headers
    let tmpheaders = new HttpHeaders({
      'x-access-token': environment.originCode, 
      'x-signature': signature,
      'x-timestamp': timestamp
    })

    const result = this.http.request(method, url, {
      body: data,
      responseType: type,
      observe: 'response', // Changed from 'body' to 'response' to access status code
      headers: tmpheaders
    });

    return new Promise((resolve, reject) => {
      result.subscribe({
        next: (response) => {
          resolve(response.body); // Resolve with the body of the response
        },
        error: (error) => {
          if (error.status === 404) {
            // Redirect to the 404 page
            this.router.navigate(['/404']);
          }
          reject(error);
        },
      });
    }
    )
  }

private async requestUpload(method: string, url: string, type: any, data?: any, headers?: any): Promise<any> {
  const timestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
  const sharedSecret = environment.secret;
  data.timestamp = timestamp;

  // Sign the request with the shared secret
  const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
      
  // Set signature in headers
  let tmpheaders = new HttpHeaders({
    'x-access-token': environment.originCode, 
    'x-signature': signature,
    'x-timestamp': timestamp
  });

  try {
    // Make the HTTP post request and return the observable
    return this.http.post<any>(url, data, {
      responseType: type,
      headers: tmpheaders
    }).toPromise(); // Convert the observable to a promise
  } catch (error: any) {
    if (error.status === 404) {
      // Redirect to the 404 page
      this.router.navigate(['/404']);
    }
    throw error;
  }
}



  /**
  * Calling the HTTP request module to generate and send the token to the Mail
  * @returns 
  */
  SendTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendTokenMail/`, 'json', obj, this.headers)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }

  SendReportTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendReportTokenMail/`, 'json', obj, this.headers)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }


  /**
    * Calling the HTTP request module to call verify the token in api index
    * @param obj
    * @returns 
    * 
    */
  VerifyToken(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/verifyToken/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call verify the token in api index
  * @param obj
  * @returns 
  * 
  */
  VerifyReportToken(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/verifyReportToken/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call getRepoSize in api_audit 
  * @param obj
  * @returns 
  * 
  */
  getRepoSize(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/getRepoSize/`, 'json', obj, this.headers);
    return res;
  }

  async uploadFile(formData: FormData): Promise<any> {
    try {
      const response = await this.requestUpload('POST', `${this.requestUrl}/upload`, 'json', formData);
      return response;
    } catch (error) {
      throw error;
    }
  }
  

  /**
  * Calling the HTTP request module to call callback in api_audit from api.github
  * @param obj
  * @returns 
  * 
  */
  getCallback(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/callback/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call callback in api_audit from api.github
  * @param obj
  * @returns 
  * 
  */
  deleteToken(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/deleteToken/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call createAssement reCaptcha and retrieve score
  * @param obj
  * @returns 
  * 
  */
  getScore(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/getScore/`, 'json', obj, this.headers);
    return res;
  }

  
  /**
  * Calling the HTTP request module to call the results route, retrieve result data from hash
  * @param obj
  * @returns 
  * 
  */
  handleHash(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/results/`, 'json', obj, this.headers);
    return res;
  }

  /**
 * Calling the HTTP request module to call the results route, retrieve result data from hash
 * @param obj
 * @returns 
 * 
 */
  handleHashReport(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/report/`, 'json', obj, this.headers);
    return res;
  }
  /**
  * Calling the HTTP request module to call the results route, retrieve result data from hash
  * @param obj
  * @returns 
  * 
  */
  async getDisplayData(obj: any) {
    const displayData = this.request('POST', `${this.requestUrl}/resultData/`, 'json', obj, this.headers);

    if (displayData) {
      return displayData;
    } else {
      return 'Could not fetch the data from the API';
    }
  }
}