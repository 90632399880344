import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { ServerService } from 'src/app/server.service';
import * as CryptoJS from 'crypto-js'
import { environment } from '../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  hash: string;
}

@Component({
  selector: 'app-report-auth',
  templateUrl: './report-auth.component.html',
  styleUrls: ['./report-auth.component.css']
})
export class ReportAuthComponent {
  @Output() verificationData = new EventEmitter<any>();

  email: string = '';
  token: string = '';
  emailVerified: boolean = false;
  tokenError: boolean = false; // To show error message
  emailError: boolean = false;
  isEmailValid: boolean = false;
  isTokenValid: boolean = false;
  isLoading: boolean = false;

  
  constructor(private serverService: ServerService,
    public dialogRef: MatDialogRef<ReportAuthComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  validateEmail(): void {
    const emailRegex = new RegExp('^([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})$');
    this.isEmailValid = emailRegex.test(this.email);
  }

  validateToken(): void {
    const tokenRegex = /^[0-9]{8}$/; // Regex for 8-digit number
    this.isTokenValid = tokenRegex.test(this.token);
  }

  sendEmail() {
    this.isLoading = true;
    const jsonStr = JSON.stringify({ email: this.email, hash: this.data.hash });
    const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString();

    this.serverService.SendReportTokenMail({ data: encryptedObject }).then(
      response => {
        // handle response
        if (response.out != "error") {
          this.emailVerified = true; // Show the token input form
          this.isLoading = false;

        } else {
          this.emailError = true
          this.isLoading = false;

        }
      },
      error => {
        // handle error
        this.emailError = true
        this.isLoading = false;
      }
    );
  }

  verifyToken() {
    this.isLoading = true;
    const jsonStr = JSON.stringify({ email: this.email, token: this.token, hash: this.data.hash });
    const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString();

    this.serverService.VerifyReportToken({ data: encryptedObject }).then(
      (response: any) => {
        if (response && typeof response === 'object' && 'isValid' in response) {
          if (response.isValid) {
            this.tokenError = false;
            //Return data for report component
            this.verificationData.emit(response.data)
          } else {
            this.tokenError = true;
            this.isLoading = false;
          }
        } else {
          // If response does not have the expected format
          console.error('Unexpected response format');
          this.tokenError = true;
          this.isLoading = false;
        }
      },
      error => {
        console.error('Verification error');
        this.tokenError = true;
        this.isLoading = false;
      }
    );
  }
}