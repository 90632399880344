<!-- progress-bar.component.html -->
<div class="grade-container" [style]="{ '--main-bg-color': color }">
    <h2>Pre-Audit Grade</h2>
    <div class="grade-circle">
        <div class="grade-value"><strong>{{grade}}/5</strong></div>
    </div>
    <!-- <h2 class="message" *ngIf="message">{{ message ? message : " " }}</h2> -->
</div>


<div class="container" [style]="{ '--percent': cssPercent + '%', '--percent2': cssPx + '%'}" >
    <!-- Progress bar -->
    <div class="progress-bar" [@progressWidth]="{ percent: percent }">
        <div class="progress" [style.width]="percent + '%'"></div>
    </div>

    <!-- Emojis based on score range -->
    <div class="emoji">
        <ng-container *ngIf="score >= 5000">💀</ng-container>
        <ng-container *ngIf="score < 5000 && score >= 1000">😕</ng-container>
        <ng-container *ngIf="score < 1000 && score >= 300">🙂</ng-container>
        <ng-container *ngIf="score >= 10 && score < 300">😃</ng-container>
        <ng-container *ngIf="score >= 0 && score < 10">😍</ng-container>
    </div>
</div>
