import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ServerService } from '../../server.service'
import { environment } from 'src/app/environments/environment'
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css'],
})
export class ResultComponent implements OnInit {
  public displayData: any
  public notionDisplayURL: any
  public shareOnTelegram: any
  public shareOnTwitter: any
  public timeZone: any
  public URLHash: any
  public URL: any
  public LAB_URL: any
  public linkButton: any
  public isMobile: boolean = false
  public isUpload: boolean = false
  public isLoading: boolean = true

  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
    public server: ServerService,
    private titleService: Title,
    private meta: Meta,
    private router: Router
  ) {
    this.displayData = {
      stoneScore: '',
      message: '',
      creationTime: '',
      expirationTime: '',
      repositoryType: '',
      GitHubURL: '',
      notionURL: '',
      pageURL: '',
      auditBookingURL: '',
      fileName: ''
    }
    // Detect the screen width
    const screenWidth = window.innerWidth

    // Define the threshold for considering the screen width as mobile
    const mobileScreenWidthThreshold = 768 // Adjust this threshold as needed

    // Update this.isMobile based on the screen width
    this.isMobile = screenWidth < mobileScreenWidthThreshold
    this.server.isMobile = this.isMobile;
  }

  async ngOnInit() {
    this.titleService.setTitle(`Result`);
    this.meta.updateTag({ name: 'description', content: 'Detailed results of your pre-audit' });
    this.meta.updateTag({ property: 'og:title', content: 'Audit - Result Page' });
    this.meta.updateTag({ property: 'og:description', content: 'Detailed results of your -preaudit' });
    this.meta.updateTag({ property: 'og:image', content: 'https://mantoine.audit.setinstone.fr/assets/logosetinstoneTW.png' });
    this.meta.updateTag({ property: 'og:url', content: 'https://mantoine.audit.setinstone.fr/result' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.updateTag({ name: 'twitter:title', content: 'Audit - Result Page' });
    this.meta.updateTag({ name: 'twitter:description', content: 'Detailed results of your pre-audit' });
    this.meta.updateTag({ name: 'twitter:image', content: 'https://mantoine.audit.setinstone.fr/assets/logosetinstoneTW.png' });
    
    // Retrieve the parameter from the URL
    this.route.params.subscribe(async (params) => {
      const paramValue = params['param']
      this.URLHash = paramValue
      console.log("URLHash:", this.URLHash)

      const URLToEncode = environment.homeUrl + '/results/' + this.URLHash
      const message = encodeURIComponent(environment.telegram_message);
      this.shareOnTelegram = `https://t.me/share/url?url=${encodeURIComponent(URLToEncode)}&text=${message}`


      this.URL = environment.homeUrl
      this.LAB_URL = environment.labUrl
      this.linkButton = environment.linkButton
      await this.serverService.handleHash({ hash: paramValue }).then(async (res: any) => {
        if(res.out == "error"){
          this.router.navigate(['/'])
        }
        this.displayData = res
        if(this.displayData.GitHubURL == "upload") {
          this.isUpload = true;
        }
        this.setTwitterLink(this.displayData.GitHubURL)
        this.isLoading = false
      })
    })

    this.displayData.repositoryType = this.displayData.repositoryType.toUpperCase()
  }

  async setTwitterLink(data: any) {
    const link = environment.homeUrl + '/results/' + this.URLHash
    const message = encodeURIComponent(environment.xmessage)
    this.shareOnTwitter = `https://twitter.com/intent/tweet?text=${message}&url=${encodeURIComponent(link)}`;
  }

  async getDisplayData(this: any) {
    const displayData = await this.http.get(environment.requestUrl + '/resultData/' + this.URLHash).toPromise()

    if (displayData) {
      return displayData
    } else {
      return 'Could not fetch the data from the API'
    }
  }

  async getCreationTimeOffset() {
    let creationTimeOffset = new Date(await this.displayData.creationTime).getTimezoneOffset()
    let creationTimeZoneOffset = 0

    while (creationTimeOffset !== 0) {
      if (creationTimeOffset < 0) {
        creationTimeOffset = creationTimeOffset + 60
        creationTimeZoneOffset = creationTimeZoneOffset + 1
      } else if (creationTimeZoneOffset > 0) {
        creationTimeOffset = creationTimeOffset - 60
        creationTimeZoneOffset = creationTimeZoneOffset - 1
      }
    }

    if (creationTimeZoneOffset === 0) {
      return this.displayData.creationTime
    } else if (creationTimeZoneOffset < 0) {
      return this.displayData.creationTime + ' - ' + creationTimeZoneOffset
    } else if (creationTimeZoneOffset > 0) {
      return this.displayData.creationTime + ' + ' + creationTimeZoneOffset
    } else {
      return 'Could not fetch expiration time'
    }
  }

  async getExpirationTimeOffset() {
    let expirationTimeOffset = new Date(await this.displayData.expirationTime).getTimezoneOffset()
    let expirationTimeZoneOffset = 0

    while (expirationTimeOffset !== 0) {
      if (expirationTimeOffset < 0) {
        expirationTimeOffset = expirationTimeOffset + 60
        expirationTimeZoneOffset = expirationTimeZoneOffset + 1
      } else if (expirationTimeZoneOffset > 0) {
        expirationTimeOffset = expirationTimeOffset - 60
        expirationTimeZoneOffset = expirationTimeZoneOffset - 1
      }
    }

    if (expirationTimeZoneOffset === 0) {
      return this.displayData.expirationTime
    } else if (expirationTimeZoneOffset < 0) {
      return this.displayData.expirationTime + ' - ' + expirationTimeZoneOffset
    } else if (expirationTimeZoneOffset > 0) {
      return this.displayData.expirationTime + ' + ' + expirationTimeZoneOffset
    } else {
      return 'Could not fetch expiration time'
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(environment.homeUrl + '/results/' + this.URLHash)
  }
}
