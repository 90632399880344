import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ServerService } from 'src/app/server.service';
import { environment } from 'src/app/environments/environment';
import { DashboardAuthComponent } from '../dashboard-auth/dashboard-auth.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  isMobile: boolean = false; // Flag to determine if the application is running on a mobile device
  isLoaded: boolean = false; // Flag to indicate if the data has been loaded
  overlayElement: HTMLElement | null = null; // Overlay element for modal dialog

  dataSource = new MatTableDataSource<any>(); // Store the data in a MatTableDataSource

  // URLs from the environment configuration
  linkHome: string = environment.homeUrl;
  linkLab: string = environment.labUrl;

  // Columns to display in the data table
  displayedColumns: string[] = ['creationDate', 'githubURL', 'reportURL', 'state', 'score', 'expirationTime'];

  @ViewChild(MatSort) sort!: MatSort; // Sorter for the data table
  @ViewChild(MatPaginator) paginator!: MatPaginator; // Paginator for the data table

  constructor(
    public server: ServerService, // Service for server-related operations
    public dialog: MatDialog, // Service for dialog operations
    private titleService: Title // Service for setting the page title
  ) {
    this.isMobile = this.server.isMobile; // Initialize mobile flag from server service
  }

  // Lifecycle hook: OnInit
  async ngOnInit() {
    this.titleService.setTitle(`Dashboard Auth`); // Set the page title
    this.openVerificationDialog(); // Open the verification dialog on initialization
  }

  // Lifecycle hook: AfterViewInit
  ngAfterViewInit() {
    // Uncomment if needed for further initialization after view load
    // this.assignSortAndPaginator();
  }

  // Create an overlay element for the modal
  createOverlay() {
    this.overlayElement = document.createElement('div'); // Create a new div element
    this.overlayElement.classList.add('modal-overlay'); // Add the overlay class
    document.body.appendChild(this.overlayElement); // Append the overlay to the body
  }

  // Remove the overlay element from the DOM
  removeOverlay() {
    if (this.overlayElement) {
      document.body.removeChild(this.overlayElement); // Remove overlay from the body
      this.overlayElement = null; // Reset overlay reference
    }
  }

  // Open the verification dialog and handle its data
  openVerificationDialog(): void {
    this.createOverlay(); // Create an overlay for the modal
    const dialogRef = this.dialog.open(DashboardAuthComponent, {
      disableClose: true, // Prevent closing the dialog on backdrop click
      width: '550px', // Set dialog width
    });

    // Subscribe to the verification data emitted from the dialog
    dialogRef.componentInstance.verificationData.subscribe(async (data) => {
      if (data) {
        // console.log("DATA DASHBOARD:", data); // Log the received data
        this.dataSource.data = data; // Assign data to the data source

        // Re-assign sort and paginator after data is set
        setTimeout(() => {
          if (this.sort && this.paginator) {
            this.dataSource.sort = this.sort; // Assign sorter to data source
            this.dataSource.paginator = this.paginator; // Assign paginator to data source
            console.log("Sort and paginator assigned after data load:", this.sort, this.paginator);
          } else {
            console.error("Sort or paginator is still undefined after data load."); // Error handling
          }
        });
        
        this.removeOverlay(); // Remove the overlay once the data is loaded
        dialogRef.close(); // Close the dialog
        this.isLoaded = true; // Mark data as loaded
      }
    });
  }

  // Assign the sort and paginator to the data source
  assignSortAndPaginator() {
    console.log("Assigning sort and paginator");
    if (this.sort && this.paginator) {
      this.dataSource.sort = this.sort; // Assign sorter to data source
      this.dataSource.paginator = this.paginator; // Assign paginator to data source
      console.log("Sort and paginator assigned:", this.sort, this.paginator);
    } else {
      console.error("Sort or paginator is undefined"); // Error handling
    }
  }
}
