<div
  style="
    background-color: #000;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  "
>
  <div>
    <h1 style="font-size: 5em; font-weight: bold">404</h1>
    <p style="font-size: 2em">Oops! Page not found.</p>
    <p style="margin-top: 20px; font-size: 1.2em">
      The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
    </p>
    <a
      href="/"
      style="
        display: inline-block;
        margin-top: 30px;
        padding: 10px 20px;
        background-color: grey;
        color: white;
        text-decoration: none;
        border-radius: 5px;
      "
      >Go to Homepage</a>
      <button (click)="contactSupport()">Contact Support</button>
  </div>
</div>
