<!-- github.component.html -->
<div *ngIf="isAuthenticated && !isMobile" class="github-div">
  <h4 class="tool-title">Your repositories</h4>
  <mat-form-field *ngIf="isAuthenticated" color="accent" style="width: 100%;">
    <mat-label>Select a Repository</mat-label>
    <mat-select [(value)]="selectedRepo" (selectionChange)="onRepoSelectionChange($event)" [disabled]="isSelectedFile" [disabled]="formData?.github !== ''">
      <mat-option *ngFor="let repo of repos" [value]="repo.name">{{ repo.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="branchesLoaded" color="accent" style="width: 100%;">
    <mat-label>Select a Branch</mat-label>
    <mat-select [(value)]="selectedBranch" (selectionChange)="onBranchSelectionChange($event)" [disabled]="isSelectedFile">
      <mat-option *ngFor="let branch of branches" [value]="branch.name">{{ branch.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="commitsLoaded" color="accent" style="width: 100%;">
    <mat-label>Select a Commit</mat-label>
    <mat-select [(value)]="selectedCommit" (selectionChange)="onCommitSelectionChange($event)" [disabled]="isSelectedFile">
      <mat-option *ngFor="let commit of commits" [value]="commit.sha">{{ commit.commit.message }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="isAuthenticated && isMobile" class="github-div">
  <h4 class="tool-title">Your repositories</h4>
  <mat-form-field color="accent">
    <mat-label>Select a Repository</mat-label>
    <mat-select [(value)]="selectedRepo" (selectionChange)="onRepoSelectionChange($event)" [disabled]="isSelectedFile" [disabled]="formData?.github !== ''">
      <mat-option *ngFor="let repo of repos" [value]="repo.name">{{ repo.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="branchesLoaded" color="accent">
    <mat-label>Select a Branch</mat-label>
    <mat-select [(value)]="selectedBranch" (selectionChange)="onBranchSelectionChange($event)" [disabled]="isSelectedFile">
      <mat-option *ngFor="let branch of branches" [value]="branch.name">{{ branch.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="commitsLoaded" color="accent">
    <mat-label>Select a Commit</mat-label>
    <mat-select [(value)]="selectedCommit" (selectionChange)="onCommitSelectionChange($event)" [disabled]="isSelectedFile">
      <mat-option *ngFor="let commit of commits" [value]="commit.sha">{{ commit.commit.message }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
