<div *ngIf="!isAuthenticated && !isMobile" class="login-div">
  <h4 class="tool-title">Private Github repository</h4>
  <button mat-raised-button type="button" (click)="login()" class="login-button">
    <div class="container">
      <app-github-icon class="login-icon"></app-github-icon>
      <b class="login-text">Login with GitHub</b>
    </div>
  </button>
</div>

<div *ngIf="!isAuthenticated && isMobile" class="login-div-mobile">
  <h4>For private Github repository</h4>
  <button mat-raised-button type="button" (click)="login()" class="login-button">
    <div class="container">
      <app-github-icon class="login-icon"></app-github-icon>
      <b class="login-text">Login with GitHub</b>
    </div>
  </button>
</div>