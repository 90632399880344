import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  // Event emitters for various actions
  public logInGithub = new EventEmitter<any>();
  public logoutGithub = new EventEmitter<any>();
  public updateRepoData = new EventEmitter<any>();
  public updateIsSelectedFile = new EventEmitter<any>();

  // Service properties
  public requestUrl = environment.requestUrl;
  public isMobile: boolean;
  public isAuth = false;
  public repoData: any;
  public isRepoDataSet = false;
  public isSelectedFile = true;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.initializeEventListeners();
  }

  /**
   * Initialize event listeners for login, logout, and data updates.
   */
  private initializeEventListeners(): void {
    this.logInGithub.subscribe(() => {
      this.isAuth = true;
      window.location.href = environment.homeUrl;
    });

    this.logoutGithub.subscribe(() => {
      this.isAuth = false;
      window.location.href = environment.homeUrl;
    });

    this.updateRepoData.subscribe(() => {
      this.isRepoDataSet = true;
    });
  }

  /**
   * Generic method to make HTTP requests with authentication.
   * @param method - HTTP method (GET, POST, etc.)
   * @param url - Endpoint URL
   * @param type - Response type
   * @param data - Request payload
   * @returns Promise with the response
   */
  private async request(method: string, url: string, type: any, data?: any): Promise<any> {
    const timestamp = Math.floor(Date.now() / 1000);
    const sharedSecret = environment.secret;
    data = { ...data, timestamp };

    const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
    
    const headers = new HttpHeaders({
      'x-access-token': environment.originCode,
      'x-signature': signature,
      'x-timestamp': timestamp.toString()
    });

    return new Promise((resolve, reject) => {
      this.http.request(method, url, {
        body: data,
        responseType: type,
        observe: 'response',
        headers: headers
      }).subscribe({
        next: (response) => resolve(response.body),
        error: (error) => {
          if (error.status === 404) {
            this.router.navigate(['/404']);
          }
          reject(error);
        }
      });
    });
  }

  /**
   * Special method for file upload requests.
   * @param method - HTTP method (typically POST for file uploads)
   * @param url - Endpoint URL
   * @param type - Response type
   * @param data - FormData containing the file
   * @returns Promise with the response
   */
  private async requestUpload(method: string, url: string, type: any, data: any): Promise<any> {
    const timestamp = Math.floor(Date.now() / 1000);
    const sharedSecret = environment.secret;
    data.append('timestamp', timestamp.toString());

    const signature = CryptoJS.HmacSHA256(JSON.stringify(Object.fromEntries(data)), sharedSecret).toString(CryptoJS.enc.Hex);
    
    const headers = new HttpHeaders({
      'x-access-token': environment.originCode,
      'x-signature': signature,
      'x-timestamp': timestamp.toString()
    });

    try {
      return await this.http.post<any>(url, data, {
        responseType: type,
        headers: headers
      }).toPromise();
    } catch (error: any) {
      if (error.status === 404) {
        this.router.navigate(['/404']);
      }
      throw error;
    }
  }

  /**
   * Send a token to the user's email.
   * @param obj - Object containing email details
   * @returns Promise with the response
   */
  SendTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendTokenMail/`, 'json', obj);
  }

  /**
   * Send a report token to the user's email.
   * @param obj - Object containing email details
   * @returns Promise with the response
   */
  SendReportTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendReportTokenMail/`, 'json', obj);
  }

  /**
   * Send a dashboard token to the user's email.
   * @param obj - Object containing email details
   * @returns Promise with the response
   */
  SendDashboardTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendDashboardTokenMail/`, 'json', obj);
  }

  /**
   * Verify a token.
   * @param obj - Object containing the token
   * @returns Promise with the verification result
   */
  VerifyToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/verifyToken/`, 'json', obj);
  }

  /**
   * Verify a report token.
   * @param obj - Object containing the report token
   * @returns Promise with the verification result
   */
  VerifyReportToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/verifyReportToken/`, 'json', obj);
  }

  /**
   * Verify a dashboard token.
   * @param obj - Object containing the dashboard token
   * @returns Promise with the verification result
   */
  VerifyDashboardToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/verifyDashboardToken/`, 'json', obj);
  }

  /**
   * Get the size of a repository.
   * @param obj - Object containing repository details
   * @returns Promise with the repository size
   */
  getRepoSize(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/getRepoSize/`, 'json', obj);
  }

  /**
   * Upload a file.
   * @param formData - FormData containing the file to upload
   * @returns Promise with the upload result
   */
  async uploadFile(formData: FormData): Promise<any> {
    return this.requestUpload('POST', `${this.requestUrl}/upload`, 'json', formData);
  }

  /**
   * Handle GitHub callback.
   * @param obj - Object containing callback data
   * @returns Promise with the callback result
   */
  getCallback(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/callback/`, 'json', obj);
  }

  /**
   * Delete a token.
   * @param obj - Object containing the token to delete
   * @returns Promise with the deletion result
   */
  deleteToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/deleteToken/`, 'json', obj);
  }

  /**
   * Get reCAPTCHA score.
   * @param obj - Object containing reCAPTCHA data
   * @returns Promise with the reCAPTCHA score
   */
  getScore(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/getScore/`, 'json', obj);
  }

  /**
   * Handle hash for retrieving results.
   * @param obj - Object containing the hash
   * @returns Promise with the results
   */
  handleHash(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/results/`, 'json', obj);
  }

  /**
   * Handle hash for retrieving report.
   * @param obj - Object containing the hash
   * @returns Promise with the report
   */
  handleHashReport(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/report/`, 'json', obj);
  }

  /**
   * Get display data for results.
   * @param obj - Object containing data request details
   * @returns Promise with the display data
   */
  async getDisplayData(obj: any): Promise<any> {
    try {
      return await this.request('POST', `${this.requestUrl}/resultData/`, 'json', obj);
    } catch (error) {
      console.error('Failed to fetch display data:', error);
      return 'Could not fetch the data from the API';
    }
  }
}