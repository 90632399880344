// github.service.ts
import { Injectable } from '@angular/core';
import { Octokit } from '@octokit/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerService } from './server.service';
import { environment } from './environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class GithubService {
  private clientId = environment.clientId;
  private clientIdApp = environment.clientIdApp;
  private homeUrl = environment.homeUrl;
  private githubUrl = environment.githubUrl;
  private secretKeyCookie = environment.secretKeyCookie;
  private octokit: Octokit;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

  constructor(public server: ServerService) {
    this.octokit = new Octokit();

    const encryptedAccessToken = sessionStorage.getItem('token');
    if (encryptedAccessToken) {
      const accessToken = CryptoJS.AES.decrypt(encryptedAccessToken, this.secretKeyCookie).toString(CryptoJS.enc.Utf8);
      if (accessToken) {
        this.octokit = new Octokit({ auth: accessToken });
        this.isAuthenticatedSubject.next(true);
      }
    }
  }

  login(): void {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${this.clientIdApp}`;
  }

  async handleCallback(): Promise<void> {
    const code = new URLSearchParams(window.location.search).get('code');

    if (code != null) {
      await this.server.getCallback({ code: code }).then((res: any) => {
        if (res.out !== 'error') {
          sessionStorage.setItem('token', res.out);
          const accessToken = CryptoJS.AES.decrypt(res.out, this.secretKeyCookie).toString(CryptoJS.enc.Utf8);

          if (accessToken) {
            this.octokit = new Octokit({ auth: accessToken });
            this.isAuthenticatedSubject.next(true);
          }
        }
      });
    }
    window.location.href = this.homeUrl;
  }

  getRepos(): Observable<any[]> {
    try {
      const repos = this.octokit.request('GET /user/repos');
      return from(repos).pipe(map((response) => response.data));
    } catch (error) {
      console.error('error getRepos', error);
      return new Observable<any[]>(); // Return an empty observable if there's an error
    }
  }

  getUserDetails(): Observable<any> {
    try {
      return from(this.octokit.request('GET /user'));
    } catch (error) {
      console.error('error getUserDetails', error);
      return new Observable<any>(); // Return an empty observable if there's an error
    }
  }

  getUserInstall(): Observable<any> {
    try {
      return from(this.octokit.request('GET /user/installations'));
    } catch (error) {
      console.error('error getUserInstall', error);
      return new Observable<any>(); // Return an empty observable if there's an error
    }
  }

  getBranches(repo: string, name: string): Observable<any[]> {
    try {
      const branches = this.octokit.request(`GET /repos/{owner}/{repo}/branches`, {
        owner: name,
        repo: repo,
      });
      return from(branches).pipe(map((response) => response.data));
    } catch (error) {
      console.error('error getBranches', error);
      return new Observable<any[]>(); // Return an empty observable if there's an error
    }
  }

  getCommits(repo: string, branch: string, name: string): Observable<any[]> {
    try {
      const commits = this.octokit.request(`GET /repos/{owner}/{repo}/commits`, {
        owner: name,
        repo: repo,
        sha: branch,
      });
      return from(commits).pipe(map((response) => response.data));
      // return from(commits).pipe(
      //   map((response) => response.data),
      //   map((commits) => commits.sort((a, b) => {
      //     // Get the dates
      //     const dateA = a.commit.committer?.date;
      //     const dateB = b.commit.committer?.date;
          
      //     // Create Date objects, providing a fallback for undefined dates
      //     const dateAParsed = new Date(dateA ?? 0);
      //     const dateBParsed = new Date(dateB ?? 0);
          
      //     // Sort based on the parsed dates
      //     return dateBParsed.getTime() - dateAParsed.getTime();
      //   }))
      // );
    } catch (error) {
      console.error('error getCommits', error);
      return new Observable<any[]>(); // Return an empty observable if there's an error
    }
  }

  logout(): void {
    const code = sessionStorage.getItem('token');
    if (code) {
      this.server.deleteToken({ key: code });
    }
    sessionStorage.clear();
    this.isAuthenticatedSubject.next(false);
    this.server.logoutGithub.emit();
  }
}
