import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ServerService } from 'src/app/server.service'
import { environment } from 'src/app/environments/environment'
import { ReportAuthComponent } from '../report-auth/report-auth.component'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import * as Prism from 'prismjs'
import 'prismjs/components/prism-solidity'

interface Vulnerability {
  id: string
  check: string
  position: string
  vul_analysis: string
  vul_solution: string
  isExpanded: boolean
  impact: string
  confidence: string
}

interface VulnerabilityPattern {
  id: string
  vul_analysis: string
  vul_solution: string
  count: string
  value: string
  isExpanded: boolean
  check: string
  impact: string
  confidence: string
  position: string
  position2: []
}

interface Vulnerabilities {
  [key: string]: Vulnerability[]
}

interface VulnerabilityCategory {
  vulnerabilities: Vulnerability[]
  isExpanded?: boolean
}

interface VulnerabilityPatternCategory {
  vulnerabilities: VulnerabilityPattern[]
  isExpanded?: boolean
}

interface WarningCategory {
  warnings: Vulnerability[]
  isExpanded?: boolean
}

const severityOrder = ['High', 'Medium', 'Low', 'Informational', 'Optimization']

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css', '../../../../node_modules/prismjs/themes/prism.css'],
})
export class ReportComponent implements OnInit {
  isLoaded = false
  URLHash: any
  isHoveringText: boolean = false
  displayData: any
  coverImageUrl: any
  headerTitle: any
  pageDescription: any
  introText: any
  vulnerabilities!: Vulnerabilities
  vuln!: Vulnerability[]
  vulnerabilitiesPatterns!: VulnerabilityPattern[]
  warnings!: Vulnerability[]
  pageTitle: any
  overlayElement: HTMLElement | null = null
  sortedVulnerabilities: VulnerabilityCategory[] = []
  sortedWarnings: VulnerabilityCategory[] = []
  sortedVulnerabilitiesPattern: VulnerabilityPatternCategory[] = []
  isMobile: boolean = false
  nbVul: any
  cost: any
  public URL: any
  public LAB_URL: any
  k1: number = environment.k1
  k2: number = environment.k2
  displayedColumns: string[] = ['severity', 'count']
  linkHome: string = environment.homeUrl
  linkLab: string = environment.labUrl
  count: any
  visiblePositions: string[] = []
  showAll = false
  numPositionsToShowInitially = 3
  username!: string
  repo!: string
  free: boolean = true
  nbVuln!: number
  nbWarning!: number
  nbHigh!: number
  contactLink = environment.contactLink
  isUpload: boolean = false

  constructor(
    private route: ActivatedRoute,
    public server: ServerService,
    public dialog: MatDialog,
    private titleService: Title,
  ) {
    this.isMobile = this.server.isMobile
  }

  async ngOnInit() {
    this.titleService.setTitle(`Report`);
    // Retrieve the parameter from the URL
    this.route.params.subscribe(async (params) => {
      const paramValue = params['param']
      this.URLHash = paramValue
      this.URL = environment.homeUrl
      this.LAB_URL = environment.labUrl
      this.openVerificationDialog()
    })
  }

  toggleIconBackground(isHovering: boolean): void {
    this.isHoveringText = isHovering
  }

  toggleVulnerabilityDetail(vulnerability: Vulnerability): void {
    vulnerability.isExpanded = !vulnerability.isExpanded
  }

  toggleVulnerabilityDetailPattern(vulnerability: VulnerabilityPattern): void {
    vulnerability.isExpanded = !vulnerability.isExpanded
  }

  toggleShowAll() {
    this.showAll = !this.showAll // Toggle showAll flag
  }
  createOverlay() {
    this.overlayElement = document.createElement('div')
    this.overlayElement.classList.add('modal-overlay')
    document.body.appendChild(this.overlayElement)
  }

  removeOverlay() {
    if (this.overlayElement) {
      document.body.removeChild(this.overlayElement)
      this.overlayElement = null
    }
  }

  scrollToSection(severity: string): void {
    let serverityID = 'warnings'

    if (severity == 'vuln') {
      serverityID = 'vulnerabilities'
    } else if (severity == 'pattern') {
      serverityID = 'vulnerabilitiesPatterns'
    }

    const element = document.getElementById(serverityID)

    if (element) {
      this.smoothScrollTo(element)
    }
  }

  getSeverityId(severity: string): string {
    let severityId = ''

    switch (severity) {
      case 'vuln':
        severityId = 'vulnerabilities'
        break
      case 'pattern':
        severityId = 'vulnerabilitiesPatterns'
        break
      case 'warning':
        severityId = 'warnings'
        break
    }
    return severityId
  }

  smoothScrollTo(element: HTMLElement): void {
    const targetPosition = element.offsetTop
    const startPosition = window.scrollY
    const distance = targetPosition - startPosition
    const duration = 2000 // Duration of the scroll in milliseconds
    let start: any = null

    window.requestAnimationFrame(step)

    function step(timestamp: number) {
      if (!start) start = timestamp
      const progress = timestamp - start
      window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration))
      if (progress < duration) window.requestAnimationFrame(step)
    }

    function easeInOutCubic(t: number, b: number, c: number, d: number) {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t * t + b
      t -= 2
      return (c / 2) * (t * t * t + 2) + b
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  getSeverityIdHtml(severity: string): string {
    return severity.split(' ')[1].toLowerCase() + '-impact-vulnerability'
  }

  getGitHubURL(position: string): string {
    // Construct the GitHub URL with the file path and line number
    if (position.includes('forge-std')) {
      return ''
    }
    if (!position.includes('node_modules')) {
      return `https://github.com/${this.username}/${this.repo}/blob/master/${position.slice(0, -3)}`
    } else {
      return ''
    }
  }

  openVerificationDialog(): void {
    this.createOverlay()
    const dialogRef = this.dialog.open(ReportAuthComponent, {
      disableClose: true,
      width: '550px',
      data: { hash: this.URLHash },
    })

    dialogRef.componentInstance.verificationData.subscribe(async (verified) => {
      if (verified) {
        // Load the main component's data
        this.displayData = verified

        this.username = this.displayData.username
        this.repo = this.displayData.repository
        this.count = this.displayData.VULNERABILITY_REPORT.REPORT_JSON.COUNT
        this.isUpload = this.displayData.isUpload
        this.introText = this.processTextContent(this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.INTRO)
        this.coverImageUrl = '../../assets/cover.jpg'
        let creationTime = this.displayData.creationTime
        let dateTimeParts = creationTime.split(' ')
        let timePart = dateTimeParts[4]
        let timeWithoutSeconds = timePart.split(':').slice(0, 2).join(':')
        this.headerTitle = 'Vulnerability Report - ' + dateTimeParts.slice(0, 4).join(' ') + ' ' + timeWithoutSeconds
        this.vuln = [].concat(
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.High,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.Medium,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.Low,
        )

        this.nbVuln = this.count.VULNERABILITIES - this.vuln.length
        this.nbHigh = this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.High.length
        this.vulnerabilitiesPatterns = [].concat(
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.High,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Medium,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Low,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Informational,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Optimization,
        )

        this.warnings = [].concat(
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.High,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Medium,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Low,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Informational,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Optimization,
        )

        this.pageTitle = 'Vulnerability Report'
        this.nbVul = this.displayData.VULNERABILITY_REPORT.REPORT_JSON.NB_VUL

        if (this.displayData.selectedPlan == 'lvl1') {
          this.free = false
        }

        this.cost = this.displayData.cost
        this.processVulnerabilitiesV2()
        this.processVulnerabilitiesPattern()
        this.processWarnings()
        this.removeOverlay()
        dialogRef.close()
        this.isLoaded = true
      }
    })
  }

  getSeverityData(): any[] {
    return Object.keys(this.nbVul).map((key) => ({
      severity: key,
      count: this.nbVul[key],
      icon: this.getSeverityIcon(key),
    }))
  }

  formatSeverity(severity: string): string {
    const parts = severity.split('. ') // Splitting based on ". " to separate the prefix and severity level
    return parts.length > 1 ? parts[1].split(' ')[0].toLowerCase() : severity.toLowerCase() // Return the severity level, ensuring it's lowercase
  }

  getVulnerabilityCount(severity: string): number {
    let tmpSeverity = severity.charAt(0).toUpperCase() + severity.slice(1)
    if (tmpSeverity == 'Code') {
      tmpSeverity = 'Optimization'
    }
    const severityData = this.getSeverityData().find((item) => item.severity === tmpSeverity)
    return severityData ? severityData.count : 0
  }

  cleanJsonData(jsonData: any): any {
    if (typeof jsonData === 'string') {
      return jsonData.replace(/(```|'''|(\[''\]))/g, '');
    } else if (typeof jsonData === 'string') {
      return jsonData.replace(/Code snippet:/g, '')
    } else if (Array.isArray(jsonData)) {
      return jsonData.map((item) => this.cleanJsonData(item))
    } else if (typeof jsonData === 'object' && jsonData !== null) {
      const cleanedData: { [key: string]: any } = {} // Define the object with index signature
      Object.keys(jsonData).forEach((key) => {
        cleanedData[key] = this.cleanJsonData(jsonData[key])
      })
      return cleanedData
    } else {
      return jsonData
    }
  }

  processVulnerabilitiesV2() {
    let sortedVulnerabilities: VulnerabilityCategory[] = []
    let tmp: Vulnerability[] = []
    this.vuln.forEach((vulnerability, index) => {
      vulnerability.id = `${index + 1}`
      // vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)

      // Process other aspects of the vulnerability
      // vulnerability.position = this.highlightCodeBlocks(vulnerability.position)
      vulnerability.vul_analysis = this.highlightCodeBlocks(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.highlightCodeBlocks(vulnerability.vul_solution)
      vulnerability.vul_solution = this.highlightCodeBlocksV2(vulnerability.vul_solution)
      // vulnerability.vul_solution = this.highlightCodeBlocksV3(vulnerability.vul_solution)

      // Wrap specific text with <strong> tag
      vulnerability.vul_analysis = this.wrapTextInStrongTag(vulnerability.vul_analysis, [
        'Explanation:',
        'Explanation of vulnerability:',
        'Explanation of vulnerability:',
        'Correction:',
        'Correction example:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])
      vulnerability.vul_solution = this.wrapTextInStrongTag(vulnerability.vul_solution, [
        'Explanation:',
        'Here is the corrected code:',
        "Here's an example of the corrected code:",
        'Resources and guidance:',
        'Corrected code:',
        'Additional resources and guidance:',
        'Explanation and solution:',
        "Here's the corrected code snippet:",
        'Resources and Guidance:',
        'Correction Example:',
        "Here's an example of how you can fix the vulnerability:",
        "Here's an example of how to fix the vulnerability:",
        'Resources:',
        'Correction:',
        'Correction example:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])

      vulnerability.vul_analysis = this.processTextContent(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.processTextContent(vulnerability.vul_solution)

      vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)
      vulnerability.vul_analysis = this.cleanJsonData(vulnerability.vul_analysis)

      tmp.push(vulnerability)
    })

    sortedVulnerabilities.push({
      vulnerabilities: tmp,
      isExpanded: false,
    })

    this.sortedVulnerabilities = sortedVulnerabilities
  }

  processWarnings() {
    let sortedWarnings: VulnerabilityCategory[] = []
    let tmp: Vulnerability[] = []
    this.warnings.forEach((vulnerability, index) => {
      vulnerability.id = `${index + 1}`
      // vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)

      // vulnerability.position = this.highlightCodeBlocks(vulnerability.position)
      vulnerability.vul_analysis = this.highlightCodeBlocks(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.highlightCodeBlocks(vulnerability.vul_solution)
      vulnerability.vul_solution = this.highlightCodeBlocksV2(vulnerability.vul_solution)

      // Wrap specific text with <strong> tag
      vulnerability.vul_analysis = this.wrapTextInStrongTag(vulnerability.vul_analysis, [
        'Explanation:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])
      vulnerability.vul_solution = this.wrapTextInStrongTag(vulnerability.vul_solution, [
        'Explanation:',
        'Here is the corrected code:',
        "Here's an example of the corrected code:",
        'Resources and guidance:',
        'Corrected code:',
        'Additional resources and guidance:',
        'Explanation and solution:',
        "Here's the corrected code snippet:",
        'Resources and Guidance:',
        'Correction Example:',
        "Here's an example of how you can fix the vulnerability:",
        "Here's an example of how to fix the vulnerability:",
        'Resources:',
        'Explanation and Fix:',
        'Guidance:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
        'Recommendation:'
      ])

      vulnerability.vul_analysis = this.processTextContent(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.processTextContent(vulnerability.vul_solution)

      vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)
      vulnerability.vul_analysis = this.cleanJsonData(vulnerability.vul_analysis)

      tmp.push(vulnerability)
    })

    sortedWarnings.push({
      vulnerabilities: tmp,
      isExpanded: false,
    })

    this.sortedWarnings = sortedWarnings
  }

  processVulnerabilitiesPattern() {
    let sortedVulnerabilitiesPattern: VulnerabilityPatternCategory[] = []
    let tmp: VulnerabilityPattern[] = []
    this.vulnerabilitiesPatterns.forEach((vulnerability, index) => {
      vulnerability.id = `${index + 1}`

      vulnerability.vul_analysis = this.highlightCodeBlocks(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.highlightCodeBlocks(vulnerability.vul_solution)
      vulnerability.vul_solution = this.highlightCodeBlocksV2(vulnerability.vul_solution)

      // Wrap specific text with <strong> tag
      vulnerability.vul_analysis = this.wrapTextInStrongTag(vulnerability.vul_analysis, [
        'Explanation:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])
      vulnerability.vul_solution = this.wrapTextInStrongTag(vulnerability.vul_solution, [
        'Explanation:',
        'Here is the corrected code:',
        "Here's an example of the corrected code:",
        'Resources and guidance:',
        'Corrected code:',
        'Additional resources and guidance:',
        'Explanation and solution:',
        "Here's the corrected code snippet:",
        'Resources and Guidance:',
        'Correction Example:',
        "Here's an example of how you can fix the vulnerability:",
        "Here's an example of how to fix the vulnerability:",
        'Resources:',
        'Guidance:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])

      vulnerability.vul_analysis = this.processTextContent(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.processTextContent(vulnerability.vul_solution)

      vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)
      vulnerability.vul_analysis = this.cleanJsonData(vulnerability.vul_analysis)

      // vulnerability.position = this.renderPositionList(vulnerability.position)
      vulnerability.position2 = JSON.parse(vulnerability.position)

      tmp.push(vulnerability)
    })

    sortedVulnerabilitiesPattern.push({
      vulnerabilities: tmp,
      isExpanded: false,
    })

    this.sortedVulnerabilitiesPattern = sortedVulnerabilitiesPattern
  }

  wrapTextInStrongTag(text: string, strongKeywords: string[]): string {
    strongKeywords.forEach((keyword) => {
      const regex = new RegExp(keyword, 'g')
      text = text.replace(regex, `<strong>${keyword}</strong>`)
    })
    return text
  }

  sortVulnerabilities(vulnerabilities: any) {
    const vulnerabilitiesArray = Object.keys(vulnerabilities).map((key) => {
      return { severity: key, vulnerabilities: vulnerabilities[key] }
    })

    this.sortedVulnerabilities = vulnerabilitiesArray.sort((a, b) => {
      return severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity)
    })
  }

  processTextContent(text: string): string {
    let processedText = this.highlightInlineCode(text)

    processedText = this.highlightCodeBlocks(processedText)

    // Making links clickable
    processedText = processedText.replace(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
      '<a href="$1" target="_blank">$1</a>',
    )

    // Handling line breaks (emojis and new lines)
    processedText = processedText.replace(/\n/g, '<br/>').replace(/([\u{1F600}-\u{1F64F}])/gu, '<br/>$1')

    return processedText
  }

  highlightCodeBlocks(html: string): string {
    const codeBlockRegex = /```solidity\n([\s\S]*?)```/g
    return html.replace(codeBlockRegex, (match, code) => {
      const highlightedCode = Prism.highlight(this.escapeHtml(code), Prism.languages['solidity'], 'solidity')
      return `<pre><code class="language-solidity">${highlightedCode}</code></pre>`
    })
  }

  highlightCodeBlocksV2(html: string): string {
    const codeBlockRegex = /```\n([\s\S]*?)```/g
    return html.replace(codeBlockRegex, (match, code) => {
      const highlightedCode = Prism.highlight(this.escapeHtml(code), Prism.languages['solidity'], 'solidity')
      return `<pre><code class="language-solidity">${highlightedCode}</code></pre>`
    })
  }

  highlightCodeBlocksV3(html: string): string {
    const codeBlockRegex = /\['([\s\S]*?)'\]/g;
    return html.replace(codeBlockRegex, (match, code) => {
        const highlightedCode = Prism.highlight(this.escapeHtml(code), Prism.languages['solidity'], 'solidity');
        return `<pre><code class="language-solidity">${highlightedCode}</code></pre>`;
    });
}


  highlightCodeBlocksJSX(html: string): string {
    const codeBlockRegex = /```jsx\n([\s\S]*?)```/g
    return html.replace(codeBlockRegex, (match, code) => {
      const highlightedCode = Prism.highlight(this.escapeHtml(code), Prism.languages['solidity'], 'solidity')
      return `<pre><code class="language-solidity">${highlightedCode}</code></pre>`
    })
  }

  getLastSegment(position: string): string {
    if (position) {
      const segments = position.split('/')
      return segments[segments.length - 1]
    }
    return ''
  }

  highlightInlineCode(html: string): string {
    // Regular expression to match text within single backticks
    const inlineCodeRegex = /`([^`]+)`/g

    return html.replace(inlineCodeRegex, (match, code) => {
      return `<code class="inline-code">${this.escapeHtml(code)}</code>`
    })
  }

  renderPositionList(positionsString: string): string {
    const positions = JSON.parse(positionsString) as string[]

    let positionContainer = '<div class="position-container">' // Start with a container div

    // Add a heading for clarity
    positionContainer += '<p>Positions:</p>'

    // Add a div to contain the list of positions
    positionContainer += `<div class="position-list">`

    // Iterate through each position and add it to the container
    positions.forEach((position) => {
      // Format the position as a div element
      positionContainer += `<div class="position-item">${position}</div>`
    })

    // Close the position list and container div
    positionContainer += '</div></div>'

    return positionContainer
  }

  escapeHtml(html: string): string {
    const text = document.createTextNode(html)
    const p = document.createElement('p')
    p.appendChild(text)
    return p.innerHTML
  }

  getLetter(index: number): string {
    return String.fromCharCode(97 + index)
  }

  getSeverityKeys(): string[] {
    return Object.keys(this.nbVul)
  }

  getSeverityIcon(severity: string): string {
    const icons: { [key: string]: string } = {
      VULNERABILITIES: 'error',
      PATTERN: 'warning',
      WARNINGS: 'info',
    }
    return icons[severity]
  }

  getSeverityClass(severity: string): string {
    return `${severity.toLowerCase()}-severity`
  }
}
