<div class="top-container">
  <img class="SetInStone-logo" src="../../../assets/logosetinstone.webp" alt="Set In Stone Logo" />
  <div class="nav-links">
    <h1 class="home-title">
      <a href="{{ linkLab }}">Home</a> |
      <a href="{{ linkHome }}">Pre-Audit</a>
    </h1>
  </div>
</div>
<div *ngIf="isLoaded" class="dashboard-container">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 dashboard-table">
    <!-- Creation Date Column -->
    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
      <td mat-cell *matCellDef="let element">{{ element.creationDate | date: 'short' }}</td>
    </ng-container>

    <!-- Github URL Column -->
    <ng-container matColumnDef="githubURL">
      <th mat-header-cell *matHeaderCellDef>Github URL</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.githubURL.startsWith('https'); else plainText">
          <a [href]="element.githubURL" target="_blank">{{ element.githubURL }}</a>
        </ng-container>
        <ng-template #plainText>{{ element.githubURL }}</ng-template>
      </td>
    </ng-container>

    <!-- Report URL Column -->
    <ng-container matColumnDef="reportURL">
      <th mat-header-cell *matHeaderCellDef>Report URL</th>
      <td mat-cell *matCellDef="let element"><a [href]="element.reportURL" target="_blank">View Report</a></td>
    </ng-container>

    <!-- State Column
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let element">{{ element.state }}</td>
    </ng-container> -->

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.stripeURL; else plainState">
          <a [href]="element.stripeURL" target="_blank">{{ element.state }}</a>
        </ng-container>
        <ng-template #plainState>{{ element.state }}</ng-template>
      </td>
    </ng-container>

    <!-- Score Column -->
    <ng-container matColumnDef="score">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Score</th>
      <td mat-cell *matCellDef="let element">{{ element.score }}</td>
    </ng-container>

    <!-- Expiration Time Column -->
    <ng-container matColumnDef="expirationTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Time</th>
      <td mat-cell *matCellDef="let element">{{ element.expirationTime | date: 'short' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- Paginator -->
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
